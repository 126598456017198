import { observer } from "mobx-react-lite"
import * as React from "react"
import InputField from "../components/common/InputField"
import TitleWithSeparator from "../components/common/TitleWithSeparator"
import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navigation/Navbar"
import { images } from "../data/variables"
import { store } from "../translations/store"
import SEO from "../components/seo"
import "./contact.scss"
import { translations } from "../translations/translations"

const Contact = observer(() => {
  const [subject, setSubject] = React.useState<string>("")
  const [name, setName] = React.useState<string>("")
  const [message, setMessage] = React.useState<string>("")
  const [email, setEmail] = React.useState<string>("")
  React.useEffect(() => {
    store.setInitialLoad()
  }, [])
  return (
    <>
    <SEO 
      title='Kontakt'
      description='Kontaktirajte nas'
    />
    <div className="contact-page">
      <Navbar />
      <div className="contact-form">
        <div className="inner w-90">
          <h1 className="page-name">{translations.contactPage.title}</h1>
          <div className="form">
            <div className="head">
              <TitleWithSeparator text={translations.forms.contactTitle} />
              <p
                dangerouslySetInnerHTML={{
                  __html: `Popunite e-mail kontakt formu i izložite nam vaša pitanja! Mi ćemo se potruditi da Vam odgovorimo u najskorijem roku! <br/> <b>EVROTOM DOO</b>`,
                }}
              />
            </div>
            <div className="fields">
              <div className="inner-section">
                <div className="d-flex upper">
                  <InputField
                    value={name}
                    onChange={(v: string) => setName(v)}
                    type="text"
                    placeholder={translations.forms.name}
                  />
                  <InputField
                    value={subject}
                    onChange={(v: string) => setSubject(v)}
                    type="text"
                    placeholder={translations.forms.subject}
                  />
                </div>
                <div className="d-flex mt-2 bottom">
                  <div>
                    <InputField
                      value={email}
                      onChange={(v: string) => setEmail(v)}
                      type="text"
                      placeholder={translations.forms.email}
                    />
                    <button className="button">{translations.forms.send}</button>
                  </div>
                  <div>
                    <textarea
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      placeholder={translations.forms.message}
                      rows={4}
                    />
                  </div>
                </div>
                <div className='mobile-form d-flex flex-column'>
                  <InputField
                      value={name}
                      onChange={(v: string) => setName(v)}
                      type="text"
                      placeholder={translations.forms.name}
                  />
                  <InputField
                      value={email}
                      onChange={(v: string) => setEmail(v)}
                      type="text"
                      placeholder={translations.forms.email}
                  />
                  <InputField
                    value={subject}
                    onChange={(v: string) => setSubject(v)}
                    type="text"
                    placeholder={translations.forms.subject}
                  />
                  <textarea
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      placeholder={translations.forms.message}
                      rows={4}
                  />
                  <button className="button">{translations.forms.send}</button>
                </div>
              </div>
            </div>
            <div className="information">
              <div className="inner-section d-flex">
                <div className="left d-flex">
                  <div>
                    <h4>{translations.contactPage.hq}</h4>
                    <div className="d-flex company">
                      <div>
                        <span>
                          <b>Direktor:</b> Toma Pavlić <br />
                          <b>Adresa:</b> Kraljevačka 46, <br />
                          22400 Ruma, Srbija.
                        </span>
                      </div>
                      <div>
                        <span>
                          <b>Tel:</b> +381(0)22-479-569 <br />
                          <b>Fax:</b> +381(0)22-471-675, <br />
                          <a
                            className="mailto"
                            href="mailto:evrotom@hotmail.com"
                          >
                            evrotom@hotmail.com
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right">
                  <h4>{translations.contactPage.workHoursTitle}</h4>
                  <div>
                    <span>
                    {translations.contactPage.workHours1} <br />
                    {translations.contactPage.workHours2}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="offices">
        <div className="inner-section d-flex flex-column w-90">
          <h4>{translations.contactPage.representatives}</h4>
          <div className="list first-list d-flex">
            <Office
              name={translations.contactPage.countries.croatia}
              flags={[images.croatia]}
              description={translations.contactPage.countries.description.croatia}
            />
            <Office
              name={translations.contactPage.countries.mac}
              flags={[images.macedonia]}
              description={translations.contactPage.countries.description.mac}
            />
            <Office
              name={translations.contactPage.countries.bul}
              flags={[images.bulgaria]}
              description={translations.contactPage.countries.description.bul}
            />
            <Office
              name={translations.contactPage.countries.ukr}
              flags={[images.ukraine]}
              description={translations.contactPage.countries.description.ukraine}
            />
            <Office
              name={translations.contactPage.countries.baltic}
              flags={[images.letonia, images.lithuania, images.estonia]}
              description={translations.contactPage.countries.description.baltic}
            />
            <Office
              name={translations.contactPage.countries.scan}
              flags={[images.sweden, images.norway, images.denmark]}
              description={translations.contactPage.countries.description.scan}
            />
            <Office
              name={translations.contactPage.countries.cz}
              flags={[images.slovakia, images.czechia]}
              description={translations.contactPage.countries.description.cz}
            />
          </div>
          <div className="list d-flex lg-view">
            <Office
              name={translations.contactPage.countries.cz}
              flags={[images.slovakia, images.czechia]}
              description={translations.contactPage.countries.description.cz}
            />
          </div>
        </div>
      </div>
      <div className="location">
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe
              height="428"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=Kraljevacka%2046%20ruma&t=&z=13&ie=UTF8&iwloc=&output=embed"
              scrolling="no"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </>
  )
})

interface OfficeProps {
  flags: string[]
  name: string
  description: string
}
const Office: React.FC<OfficeProps> = ({ flags, name, description }) => {
  return (
    <div className="office">
      <div className="flags">
        {flags.map((f, i) => (
          <img key={i} src={f} />
        ))}
      </div>
      <h6 className="name">{name}</h6>
      <p
        className="description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  )
}
export default Contact
